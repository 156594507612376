import React from "react";
import "./Style.css"

const App = () => {

  return (
    <div className="main">
        <Card name={"Linkedin"} link={"https://nl.linkedin.com/in/shahnawaz-siddiki-nafe-4591b7233"}/>
        <Card name={"Malt"} link={"https://www.malt.nl/profile/shahnawazsiddikinafi"}/>
        <Card name={"Facebook"} link={"https://www.facebook.com/shahnawazsiddikinafiofficial"}/>
        <Card name={"Instagram"} link={"https://instagram.com/nafi_shs?igshid=YmMyMTA2M2Y="}/>
        <Card name={"Twitter"} link={"https://twitter.com/shahnawaznafi"}/>
    </div>
  )
}

const Card = ({name,link }) => {
    return (
        <a className="text-dc-none" target="_blank" rel="noopener noreferrer" href={link}>
        <article class="card">
            <h5 class="card__title">{name}</h5>
        </article>
        </a>
    )
}

export default App